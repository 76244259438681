
.accordion-section{
    display: flex;
    flex-direction: column;
    margin: 50px 100px;
}
.accordion{
    background-color: #F9FEFF;
    color: #041924;
    cursor: pointer;
    padding: 18px;
    display: flex;
    border-radius: 15px;
    flex-direction: column;
    align-items: center;
    border: none;
    outline: none;
    transition: background-color 0.6s ease;
}

.image{
    max-width: 100%;
    height: auto;
    border-radius:15px;
}

.accordion:hover{
    background-color: #ccc;
}
.active{
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}
.accordion-title{
    font-family: 'Roboto', sans-serif;
    font-size: 2.5rem;
    text-align: center;
    padding-top: 10px;
}
.accordion-club{
    font-family: 'Roboto', sans-serif;
    font-size: 2rem;
    text-align: center;
    color: grey;
    margin-top: 10px;
    width: 100%;
}
.club{
    padding-left: 25px;
}
.title{
    font-size: 2.5rem;
}

.accordion-icon{
    transition: transform 0.6s ease;
    float: right;
    width: 40px;
    height: 40px;
    margin-top:-5px;
}

.rotate {
    transform: rotate(90deg);
}

.accordion-content{
    background-color: #F9FEFF;
    color: #041924;
    overflow: hidden;
    transition: max-height 0.6s ease;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
}

.accordion-text{
    font-size: 1.5rem;
    padding:20px;
    line-height: 1.5;
}