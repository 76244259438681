html {
  scroll-behavior: smooth;
}
body {
  background: #041924;
  background-attachment:fixed;
  color: #F9FEFF;
}
.container{
  padding-left: 8%;
  display: grid;
  grid-template-columns: 600px min-content 1fr;
  gap: 1rem;
  align-items: center;
}

.subTitle{
  font-size: 3rem;
}

.intro{
  font-family: "Playfair Display";
  font-weight: 400;
  font-size: 4rem;
  padding-top: 20%;
  margin-bottom: 25%;
  color:#F9FEFF;
}

.left{
  padding-left: 8%;
}

.name{
  color: #75f0c3;
;
}

.positions{
  font-size: 3rem;
}

footer{
  text-align: center;
}